@font-face {
  font-weight: normal;
  font-family: 'Museo Sans';
  font-style: normal;
  src: url('../../../fonts/MuseoSans-500.woff2') format('woff2'),
    url('../../../fonts/MuseoSans-500.woff') format('woff');
}

#TenantLogin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  font-family: 'Museo Sans', Helvetica, sans-serif;
  background-color: azure;
  background-image: url('../../../assets/login-background.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.TenantLogin__container {
  width: 35rem;
  padding: 2rem;
  background: #007c78;
  border-radius: 10px;
  box-shadow: rgba(14, 30, 37, 0.12) 0 2px 4px 0, rgba(14, 30, 37, 0.32) 0 2px 16px 0;
  opacity: 0.95;
}

.TenantLogin__container--logo {
  margin-bottom: 20px;
  text-align: center;
}

.TenantLogin__container--logo img {
  max-width: 220px;
}

.TenantLogin__container--button {
  width: 100%;
  color: #264699 !important;

  &:hover, &:focus {
    color: #4dbdb2 !important;
    background: #fff !important;
    border-color: #4dbdb2 !important;
  }
}
