@font-face {
  font-weight: normal;
  font-family: 'Museo Sans';
  font-style: normal;
  src: url('../../../fonts/MuseoSans-500.woff2') format('woff2'),
    url('../../../fonts/MuseoSans-500.woff') format('woff');
}

#login-landing {
  height: 100vh;
  font-family: 'Museo Sans', Helvetica, sans-serif;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: azure;
  background-image: url('../../../assets/login-background.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.overlay-container {
  display: flex;
  align-content: space-between;
  align-items: flex-start;
  width: 415px;
  height: 210px;
  margin-left: 80px;
  border-radius: 10px;
  box-shadow: rgba(14, 30, 37, 0.12) 0 2px 4px 0, rgba(14, 30, 37, 0.32) 0 2px 16px 0;
  opacity: 0.95;
}

.overlay-container-left,
.overlay-container-right {
  flex: 1;
  height: 100%;
  color: #fff;
  text-align: center;
}

.overlay-container-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px 0 0 10px;
}

.overlay-container-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #04aa9d;
  border-radius: 0 10px 10px 0;
}

.logo {
  max-width: 80px;
  margin-bottom: 20px;
}

.login-button {
  display: inline-block;
  margin: 10px;
  padding: 8px 20px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
  background-color: #04aa9d;
  border: 1px solid #04aa9d;
  border-radius: 20px;
  cursor: pointer;
}

.project-title { 
  color: #fff;
  font-weight: 100;
}

.terms-area {
  margin-bottom: 20px;
  font-size: 10px;
}

.terms-area a {
  color: #fff;
}

.version-area {
  display: flex;
  flex-direction: column;
}

.version-area h4, 
.version-area h5 {
  color: #fff;
}
